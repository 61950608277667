import React, { memo } from 'react'
import {
  Button as ButtonBase,
  ButtonProps as ButtonBaseProps,
  styled,
} from '@mui/material'

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.primary.main,
  lineHeight: 1,
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightRegular,
  position: 'relative',
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: `${theme.spacing(2)} !important`,
  paddingBottom: theme.spacing(1),
  minWidth: 'auto',
  svg: {
    fontSize: '1.5rem !important',
  },
  transition: `${theme.transitions.create(['color', 'opacity'])} !important`,
  ['&:hover']: {
    color: 'white',
  },
  ['&:after']: {
    display: 'block',
    content: '""',
    width: '100%',
    height: '1px',
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
  },
  ['&.Mui-disabled']: {
    opacity: 0.5,
    color: theme.palette.primary.main,
    ['&:hover']: {
      color: 'inherit',
    },
  },
}))

const TextButton = ({ ...props }: ButtonBaseProps) => {
  return (
    <StyledButton disableElevation disableRipple variant="text" {...props} />
  )
}

export default memo(TextButton)
