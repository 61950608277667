// npm
import React, { memo } from 'react'
import {
  Box,
  BoxProps,
  Container,
  Stack,
  styled,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, FreeMode } from 'swiper'
import 'swiper/css'

// components
import TextButton from '@atoms/buttons/TextButton'
import IconCard from '@molecules/cards/IconCard'
import TextContent from '@molecules/text/TextContent'

// constants
import { firstSectionSpacing } from '@constants/firstSectionSpacing'

export interface SectionIconCardSliderTemplateProps extends BoxProps {
  smallHeading?: string
  mainHeading: string
  text: string
  bgColor: string
  cards: Queries.DatoCmsService[] | Queries.DatoCmsIconBulletPoint[]
  isFirstSection?: boolean
}

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
}))

const SwiperContainer = styled(Box)(({ theme }) => ({
  ['.swiper-cards']: {
    overflow: 'visible',
  },
  ['.swiper-wrapper']: {
    alignItems: 'stretch',
  },
  ['.swiper-slide']: {
    height: 'auto',
    alignSelf: 'stretch',
    overflow: 'hidden',
  },
}))

const SectionIconCardSliderTemplate = ({
  text,
  smallHeading,
  mainHeading,
  bgColor,
  cards,
  isFirstSection,
  ...props
}: SectionIconCardSliderTemplateProps) => {
  const theme = useTheme()
  const [prevDisabled, setPrevDisabled] = React.useState(true)
  const [nextDisabled, setNextDisabled] = React.useState(false)
  const swiperRef = React.useRef<SwiperCore>()
  SwiperCore.use([Navigation, FreeMode])

  const setDisabledNav = () => {
    setPrevDisabled(swiperRef.current.isBeginning ? true : false)
    setNextDisabled(swiperRef.current.isEnd ? true : false)
  }
  return (
    <Wrapper
      component="section"
      overflow="hidden"
      bgcolor={`${bgColor}`}
      pt={isFirstSection ? firstSectionSpacing : { xs: 6, md: 10 }}
      pb={{ xs: 6, md: 10 }}
      {...props}
    >
      <Container maxWidth="xl">
        <Grid container spacing={0} mb={{ xs: 3, md: 6 }}>
          <Grid md={8} lg={6}>
            <TextContent
              smallHeading={smallHeading}
              mainHeading={mainHeading}
              text={text}
            />
          </Grid>
          {(!nextDisabled || !prevDisabled) && (
            <Grid
              md={4}
              lg={3}
              mt={{ xs: 3, md: 0 }}
              ml={{ xs: 0, md: 'auto' }}
              display="flex"
              justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
              alignItems="flex-end"
            >
              <Stack direction="row" spacing={2}>
                <TextButton
                  disabled={prevDisabled}
                  onClick={() => {
                    swiperRef.current?.slidePrev()
                  }}
                >
                  <Box pr={2}>Prev</Box>
                </TextButton>
                <TextButton
                  disabled={nextDisabled}
                  onClick={() => {
                    swiperRef.current?.slideNext()
                  }}
                >
                  <Box pl={2}>Next</Box>
                </TextButton>
              </Stack>
            </Grid>
          )}
        </Grid>

        <SwiperContainer>
          <Swiper
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper
            }}
            onInit={(swiper) => {
              swiper.navigation.init()
              swiper.navigation.update()
            }}
            onActiveIndexChange={() => {
              setDisabledNav()
            }}
            onReachBeginning={() => {
              setDisabledNav()
            }}
            onReachEnd={() => {
              setDisabledNav()
            }}
            onResize={() => {
              setDisabledNav()
            }}
            slidesPerView={1}
            spaceBetween={0}
            observer={true}
            observeParents={true}
            className="swiper-cards"
            freeMode={{
              sticky: true,
            }}
            breakpoints={{
              [theme.breakpoints.values.xs]: {
                slidesPerView: 1,
              },
              [theme.breakpoints.values.sm]: {
                slidesPerView: 2,
              },
              [theme.breakpoints.values.md]: {
                slidesPerView: 3,
              },
              [theme.breakpoints.values.lg]: {
                slidesPerView: 4,
              },
              [theme.breakpoints.values.xl]: {
                slidesPerView: 5,
              },
            }}
          >
            <Box position="relative">
              {cards.map((item: any, index: number) => {
                if (item.model && item.model.apiKey) {
                  if (item.model.apiKey === 'service') {
                    const service = item as Queries.DatoCmsService
                    return (
                      <SwiperSlide key={service.originalId}>
                        <IconCard
                          iconUrl={service.icon.url}
                          heading={service.name}
                          buttonLabel="Read More"
                          useAlternateColor={index % 2 === 0}
                          slug={service.slug}
                          apiKey={service.model.apiKey}
                        />
                      </SwiperSlide>
                    )
                  }
                  if (item.model.apiKey === 'icon_bullet_point') {
                    const point = item as Queries.DatoCmsIconBulletPoint
                    return (
                      <SwiperSlide key={point.originalId}>
                        <IconCard
                          iconUrl={point.icon.url}
                          heading={point.text}
                          useAlternateColor={index % 2 === 0}
                        />
                      </SwiperSlide>
                    )
                  }
                }
              })}
            </Box>
          </Swiper>
        </SwiperContainer>
      </Container>
    </Wrapper>
  )
}

export default memo(SectionIconCardSliderTemplate)
