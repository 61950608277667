type node = {
  node: Queries.DatoCmsService | Queries.DatoCmsProject
}

const extractDatoCmsArray = (items: node[]) => {
  let cards: Queries.DatoCmsService[] | Queries.DatoCmsProject[] = []
  items.map((item: any) => {
    cards.push(item.node)
  })
  return cards
}

export default extractDatoCmsArray
