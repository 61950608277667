// npm
import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// components
import SectionIconCardSliderTemplate, {
  SectionIconCardSliderTemplateProps,
} from './templates/SectionIconCardSliderTemplate'

// helpers
import extractDatoCmsArray from '@helpers/extractDatoCmsArray'

const SectionServiceSlider = (
  props: Omit<SectionIconCardSliderTemplateProps, 'cards'>
) => {
  const data = useStaticQuery<Queries.SectionServiceSliderQuery>(graphql`
    query SectionServiceSlider {
      allDatoCmsService(sort: { fields: position, order: ASC }) {
        edges {
          node {
            ...serviceCard
          }
        }
      }
    }
  `)

  return (
    <SectionIconCardSliderTemplate
      cards={extractDatoCmsArray(data.allDatoCmsService.edges)}
      {...props}
    />
  )
}

export default memo(SectionServiceSlider)
